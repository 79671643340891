<template>
  <div class="pa-3">
    <v-form ref="form" v-model="valid" lazy-validation>
      <v-dialog
        v-model="showForm"
        max-width="1200px"
        persistent
        transition="dialog-top-transition"
        @keydown.esc="close"
      >
        <v-card>
          <v-card-title>
            <span class="headline">Advanced Filter</span>
          </v-card-title>
          <v-divider></v-divider>
          <v-card-text>
            <v-container>
              <v-row class="mt-1">
                <v-col cols="12" sm="3" md="3">
                  <v-autocomplete
                    v-model="champ"
                    :items="columns_list.filter((elm) => elm.enum != null)"
                    item-value="value"
                    item-text="text"
                    dense
                    label="Column"
                    small-chips
                    class="mt-2"
                    @change="champ_change"
                  ></v-autocomplete>
                </v-col>
                <v-col
                  cols="12"
                  sm="3"
                  md="3"
                  v-if="champ_type != 'list' && champ_type != 'checkbox'"
                >
                  <v-autocomplete
                    v-model="opr"
                    :items="oprs"
                    item-value="id"
                    item-text="label"
                    dense
                    label="Operator"
                    small-chips
                    class="mt-2"
                  ></v-autocomplete>
                </v-col>
                <v-col
                  cols="12"
                  sm="3"
                  md="3"
                  v-if="champ_type == 'list' || champ_type == 'checkbox'"
                >
                  <v-autocomplete
                    v-model="list_sel"
                    :items="champ_type == 'checkbox' ? ['Yes', 'No'] : list"
                    dense
                    :label="champ_type == 'checkbox' ? 'Yes/No' : 'Choice'"
                    small-chips
                    class="mt-2"
                    :multiple="champ_type == 'list'"
                  ></v-autocomplete>
                </v-col>
                <v-col
                  cols="12"
                  sm="3"
                  md="2"
                  v-if="
                    opr &&
                    champ_type != 'date' &&
                    champ_type != 'list' &&
                    champ_type != 'checkbox' &&
                    opr != 8 &&
                    opr != 9
                  "
                >
                  <v-text-field
                    autocomplete="off"
                    v-model="value1"
                    label="Value"
                    dense
                  >
                  </v-text-field>
                </v-col>
                <v-col
                  cols="12"
                  sm="3"
                  md="2"
                  v-if="
                    opr &&
                    opr == 7 &&
                    champ_type != 'date' &&
                    champ_type != 'list' &&
                    champ_type != 'checkbox'
                  "
                >
                  <v-text-field
                    autocomplete="off"
                    v-model="value2"
                    label="And Value"
                    dense
                  >
                  </v-text-field>
                </v-col>
                <v-col
                  cols="12"
                  sm="3"
                  md="2"
                  v-if="opr && champ_type == 'date' && opr != 8 && opr != 9"
                >
                  <datepicker
                    label="Date "
                    v-model="value1"
                    :rules="[
                      (v) =>
                        !v ||
                        verif_date(value1, value2) ||
                        value2 == null ||
                        'Erreur Date ',
                    ]"
                    :edit="true"
                    :key="cs1"
                  ></datepicker>
                </v-col>
                <v-col
                  cols="12"
                  sm="3"
                  md="2"
                  v-if="opr && opr == 7 && champ_type == 'date'"
                >
                  <datepicker
                    label="And Date "
                    v-model="value2"
                    :rules="[
                      (v) =>
                        !v ||
                        verif_date(value1, value2) ||
                        value1 == null ||
                        'Error Date ',
                    ]"
                    :edit="true"
                    :key="cs2"
                  ></datepicker>
                </v-col>
                <v-col cols="12" sm="3" md="2">
                  <v-btn
                    icon
                    @click="update_filtre"
                    :disabled="
                      !champ ||
                      (!value1 &&
                        opr != 8 &&
                        opr != 9 &&
                        champ_type != 'list' &&
                        champ_type != 'checkbox') ||
                      (opr == 7 && !value2 && champ_type != 'list') ||
                      ((champ_type == 'list' || champ_type == 'checkbox') &&
                        list_sel.length == 0)
                    "
                  >
                    <v-icon color="success" large>mdi-filter-plus</v-icon>
                  </v-btn>
                </v-col>
              </v-row>
              <v-divider v-if="champs_sel.length > 0"></v-divider>
              <v-chip-group class="mt-3" column v-if="champs_sel.length > 0">
                <v-chip
                  class="ma-2"
                  close
                  close-icon="mdi-filter-remove"
                  color="orange"
                  label
                  v-for="champ in champs_sel"
                  :key="champ.value1"
                  @click:close="delchips(champ)"
                >
                  {{ champ.champ_name }} {{ champ.opr_name }}
                  {{
                    champ.champ_type == "date"
                      ? datefr(champ.value1)
                      : champ.opr
                      ? champ.value1
                      : champ.list_sel
                  }}
                  {{
                    champ.opr == 7
                      ? " Et " +
                        (champ.champ_type == "date"
                          ? datefr(champ.value2)
                          : champ.value2)
                      : ""
                  }}
                </v-chip>
              </v-chip-group>
            </v-container>
          </v-card-text>
          <v-divider></v-divider>
          <v-card-actions>
            <v-btn
              class="ml-2"
              text
              color="success"
              @click="filtrer"
              :disabled="champs_sel.length == 0"
            >
              <v-icon> mdi-filter </v-icon>
              Filtrer
            </v-btn>

            <v-spacer></v-spacer>
            <v-btn text @click="close" class="mr-2"> Close </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-form>
    <v-snackbar
      v-model="snackbar"
      :timeout="snackbar_timeout"
      top
      :color="snackbar_color"
    >
      {{ snackbar_text }}
    </v-snackbar>
  </div>
</template>

<script>
import { dateam } from "@/utils/functions.js";
export default {
  name: "filteravc",
  components: {
    datepicker: () => import("./DatePicker.vue"),
  },
  props: {
    showForm: Boolean,
    columns_list: Array,
    items: Array,
  },
  data: () => ({
    afficher: false,
    valid: true,
    service: [],
    types: [],
    snackbar: false,
    snackbar_timeout: 2000,
    snackbar_text: "",
    snackbar_color: "primary",
    champs_sel: [],
    champ: null,
    champ_type: null,
    value1: null,
    value2: null,
    cs1: 100,
    cs2: 200,
    oprs: [],
    opr: null,
    filter_text: [],
    list: [],
    list_sel: [],
  }),

  computed: {
    dateam() {
      return dateam;
    },
  },
  watch: {},

  created() {},

  methods: {
    verif_date(date1, date2) {
      let d1 = new Date(date1);
      let d2 = new Date(date2);
      return d2 >= d1;
    },
    champ_change() {
      this.value1 = null;
      this.value2 = null;
      this.oprs = [
        { id: 1, label: " equals ", enum: "EQ" },
        { id: 2, label: " not equals ", enum: "NEQ" },
        { id: 3, label: " greater or equal than ", enum: "GTE" },
        { id: 4, label: " greater than ", enum: "GT" },
        { id: 5, label: " less or equal than ", enum: "LTE" },
        { id: 6, label: " less than ", enum: "LT" },
        { id: 7, label: " between ", enum: "BETWEEN" },
        { id: 8, label: " is empty ", enum: "IS_NULL" },
        { id: 9, label: " not empty ", enum: "IS_NOT_NULL" },
      ];
      this.list_sel = [];
      this.opr = null;
      this.opr_name = null;
      let i = this.columns_list.findIndex((elm) => elm.value == this.champ);
      if (i >= 0) {
        if (this.columns_list[i].list) {
          this.champ_type = "list";
          this.list = this.columns_list[i].list;
        } else {
          let v;
          let j = this.items.findIndex((elm) => elm[this.champ] != null);
          if (j >= 0) v = this.items[j][this.champ];
          else if (this.items.length > 0) v = this.items[0][this.champ];
          const regex = /^[-\d]+$/;
          if (regex.test(v)) {
            if (typeof v === "string") {
              if (v.includes("-")) v = new Date(v);
            }
          }

          if (
            (v instanceof Date && !isNaN(v)) ||
            this.columns_list[i].slot == "date"
          )
            this.champ_type = "date";
          else if (
            this.columns_list[i].slot == "checkbox" ||
            this.columns_list[i].search == "yes/no"
          )
            this.champ_type = "checkbox";
          else if (
            typeof v === "number" ||
            this.columns_list[i].slot == "number" ||
            this.columns_list[i].slot == "cur"
          )
            this.champ_type = "number";
          else {
            this.champ_type = "text";
            this.oprs.push({ id: 10, label: "Like ", enum: "LIKE" });
            this.oprs.push({
              id: 11,
              label: "not Like ",
              enum: "NOT_LIKE",
            });
          }
        }
      }
      /////
    },
    delchips(item) {
      let i = this.champs_sel.findIndex((elm) => elm.value_id == item.value_id);
      if (i >= 0) {
        this.champs_sel.splice(i, 1);
      }
    },
    close() {
      this.$emit("close");
    },
    update_filtre() {
      let i;
      let champ = this.columns_list.find((elm) => elm.value == this.champ);
      let opr = this.oprs.find((elm) => elm.id == this.opr);
      i = this.champs_sel.findIndex((elm) => elm.value_id == this.champ);

      if (i >= 0) {
        this.champs_sel[i] = {
          ...this.champs_sel[i],
          ...{
            champ_name: champ.text,
            enum: champ.enum,
            opr: this.opr,
            opr_name: this.opr ? opr.label : " :",
            opr_enum: this.opr ? opr.enum : "IN",
            value1: this.value1,
            value2: this.value2,
            list_sel: this.list_sel,
            champ_type: this.champ_type,
          },
        };
      } else
        this.champs_sel.push({
          value_id: this.champ,
          opr: this.opr,
          value1: this.value1,
          value2: this.value2,
          list_sel: this.list_sel,
          champ_type: this.champ_type,
          champ_name: champ.text,
          opr_name: this.opr ? opr.label : " : ",
          enum: champ.enum,
          opr_enum: this.opr ? opr.enum : "IN",
        });
      this.value1 = null;
      this.value2 = null;
      this.cs1++;
      this.cs2++;
    },
    filtrer() {
      let filter_label = "";
      this.filter_text = [];

      this.champs_sel.forEach((element) => {
        filter_label += `${filter_label === "" ? "" : " And "}${
          element.champ_name
        } ${element.opr_name}${
          element.champ_type === "date"
            ? this.dateam(element.value1)
            : element.opr
            ? element.value1
            : element.list_sel.toString()
        }${
          element.opr === 7
            ? " Et " +
              (element.champ_type === "date"
                ? this.dateam(element.value2)
                : element.value2)
            : ""
        }`;

        let v = element.value1;
        if (element.champ_type == "checkbox") {
          if (element.list_sel == ["Yes"]) v = ["1"];
          if (element.list_sel == ["No"]) v = ["0"];
        }
        //list
        else if (!element.opr) v = element.list_sel;
        //betweem
        else if (element.opr == 7) v = [element.value1, element.value2];
        //like or not like
        else if (element.opr == 10 || element.opr == 11)
          v = "%" + element.value1 + "%";
        this.filter_text.push({
          column: element.enum,
          operator: element.opr_enum,
          value: v,
        });
      });

      this.$emit("close", this.filter_text, filter_label);
    },
  },
};
</script>
